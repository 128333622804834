import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getRules = async () => {
    const result = await makeGetRequest(API.rules.get);

    return result.data.result.data.items;
};

export const editRules = async (data: any) => {
    const result = await makeFormRequest(API.rules.edit, data);

    return result;
};
